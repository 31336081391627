import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { IndexHero, IndexList } from "@modules/indexes/index";
import {
  useResearchIndexData,
  useAllResearchArticlesData,
} from "@staticQueries";
import { SEO, PreFooter } from "@common";
import { Section, Container } from "@ui";

const ResearchIndex = () => {
  const { meta, hero, prefooter } = useResearchIndexData();
  const [{ indexPages }, dispatch] = useAppState();
  const { research } = indexPages;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  const { articles } = useAllResearchArticlesData();

  return (
    <>
      <SEO {...meta} />
      <IndexHero heading={hero.heading} description={hero.description} />

      <Section size="sm">
        <Container>
          <IndexList items={articles} state={research} type="research" />
        </Container>
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default ResearchIndex;
